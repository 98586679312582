import * as Sentry from '@sentry/nextjs'
import moment from 'moment'

import { api } from 'src/api'

export const VIDEO_PROGRESS_INTERVAL = 30

class ClassTracker {
  endpoint: string

  constructor() {
    const urlFromEnv = process.env.NEXT_PUBLIC_CLASS_TRACKING_URL
    if (!urlFromEnv) {
      throw new Error('Missing NEXT_PUBLIC_CLASS_TRACKING_URL env variable.')
    }
    this.endpoint = urlFromEnv
  }

  track(event: string | undefined, properties: Record<string, unknown> = {}) {
    if (event) {
      const id = properties?.videoId || properties?.eventId || properties?.id
      const userId = api.sessionState?.userId || properties?.userId
      const videoType = 'vod'
      const duration = properties.duration
      const videoTitle = (properties.videoTitle as string)?.trim()

      if (!userId) {
        Sentry.captureException('classTracker - missing userId', {
          level: 'debug',
        })
        throw new Error('classTracker - missing userId')
      }

      if (!id) {
        Sentry.captureException('classTracker - missing properties.id', {
          level: 'debug',
        })
        throw new Error('classTracker - missing id')
      }

      const payload = {
        userId,
        context: { library: { name: 'analytics.js' } },
        event: event,
        originalTimestamp: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        properties: {
          deviceType: 'web',
          duration,
          id,
          from: properties?.from,
          isAirplay: !!properties?.isAirplay,
          isCasting: !!properties?.isCasting,
          isStacked: !!properties?.isStacked,
          isMuted: !!properties?.isMuted,
          isRecommended: !!properties?.isRecommended,
          isWorkoutParty: !!properties?.isWorkoutParty,
          videoTitle,
          videoType,
        },
      }

      fetch(this.endpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}

const _classTracker = new ClassTracker()

export const classTracker = _classTracker
