/* 
  PURPOSE: Do not log users out when network fails for these url paths
  TO ADD ROUTES: Add the route to the PERSIST_LOGIN_NO_NETWORK object with url logic to determine if the route is active
*/

export const shouldNotLogUserOutOnNoNetwork = ({
  windowLocationParts,
}: {
  windowLocationParts: Array<string>
}) => {
  const PERSIST_LOGIN_NO_NETWORK: Record<string, boolean> = {
    VIDEO_DETAILS_PAGE: windowLocationParts[1] === 'videos' && windowLocationParts[2]?.length > 0,
  }

  let result = 0

  Object.keys(PERSIST_LOGIN_NO_NETWORK).forEach((key: string) => {
    return PERSIST_LOGIN_NO_NETWORK[key] ? (result += 1) : null
  })

  return !!result
}
