export type JSONSerializable =
  | string
  | number
  | boolean
  | null
  | JSONSerializable[]
  | { [key: string]: JSONSerializable }

interface HttpClientConfig {
  baseURL?: string
  headers?: Record<string, string>
}

export interface HttpClientResponse<T> {
  data: T
  status: number
  statusText: string
  headers: Record<string, string>
}

export interface HttpClientError<T> extends Error {
  response?: HttpClientResponse<T>
}

interface HttpClientRequestConfig extends RequestInit {
  headers?: Record<string, string>
  params?: Record<string, string | string[] | number | boolean | Date> & { signal?: AbortSignal }
}

type ResponseInterceptor<T> = (
  response: HttpClientResponse<T>,
) => HttpClientResponse<T> | Promise<HttpClientResponse<T>>
type ErrorInterceptor<T> = (
  error: HttpClientError<T>,
) => HttpClientError<T> | Promise<HttpClientError<T>>

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export const isAbort = (error: Error) => error.name === 'AbortError'

const headersToObject = (headers: Headers): Record<string, string> => {
  if (!headers) {
    return {}
  }

  return Array.from(headers.entries()).reduce(
    (prev, [key, val]) => ({
      ...prev,
      [key]: val,
    }),
    {},
  )
}

export class HttpClient {
  private baseURL: string
  private defaultHeaders: Record<string, string>
  private responseInterceptors: ResponseInterceptor<any>[] = []
  private errorInterceptors: ErrorInterceptor<any>[] = []

  constructor({ baseURL = '', headers = {} }: HttpClientConfig) {
    this.baseURL = baseURL
    this.defaultHeaders = headers
  }

  private async request<T, D extends JSONSerializable = JSONSerializable>(
    url: string,
    method: HttpMethod,
    data?: D,
    options?: HttpClientRequestConfig,
  ): Promise<HttpClientResponse<T>> {
    const searchParams = new URLSearchParams()
    Object.entries(options?.params ?? {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) =>
        searchParams.append(key, value instanceof Date ? value.toJSON() : String(value)),
      )
    const queryParamsString = searchParams.toString()
    const apiUrl = `${url?.startsWith('/') ? '' : '/'}${url}`
    const queryPrefix = queryParamsString ? (apiUrl.includes('?') ? '&' : '?') : ''
    const fullUrl = `${this.baseURL}${apiUrl}${queryPrefix}${queryParamsString ?? ''}`

    const response = await fetch(fullUrl, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...this.defaultHeaders,
        ...(options?.headers ?? {}),
      },
      body: data ? JSON.stringify(data) : undefined,
      ...(options?.cache === 'no-store'
        ? {}
        : { next: { revalidate: parseInt(process.env.DEFAULT_REVALIDATE_SECONDS ?? '60') } }),
      ...options,
    })

    let responseData
    try {
      responseData = await response.json()
    } catch (error) {
      responseData = {}
    }

    if (!response.ok) {
      const error: HttpClientError<T> = new Error(response.statusText)
      error.response = {
        data: responseData,
        status: response.status,
        statusText: response.statusText,
        headers: headersToObject(response.headers),
      }
      for (const interceptor of this.errorInterceptors) {
        await interceptor(error)
      }
      throw error
    }

    let transformedResponse = {
      data: responseData,
      status: response.status,
      statusText: response.statusText,
      headers: headersToObject(response.headers),
    }
    for (const interceptor of this.responseInterceptors) {
      transformedResponse = await interceptor(transformedResponse)
    }
    return transformedResponse
  }

  async get<T>(url: string, options?: HttpClientRequestConfig): Promise<HttpClientResponse<T>> {
    return this.request<T>(url, 'GET', undefined, options)
  }

  async post<T, TData extends JSONSerializable = JSONSerializable>(
    url: string,
    data?: TData,
    options?: HttpClientRequestConfig,
  ): Promise<HttpClientResponse<T>> {
    return this.request<T>(url, 'POST', data, options)
  }

  async put<T, TData extends JSONSerializable = JSONSerializable>(
    url: string,
    data?: TData,
    options?: HttpClientRequestConfig,
  ): Promise<HttpClientResponse<T>> {
    return this.request<T>(url, 'PUT', data, options)
  }

  async patch<T, TData extends JSONSerializable = JSONSerializable>(
    url: string,
    data?: TData,
    options?: HttpClientRequestConfig,
  ): Promise<HttpClientResponse<T>> {
    return this.request<T>(url, 'PATCH', data, options)
  }

  async delete<T, TData extends JSONSerializable = JSONSerializable>(
    url: string,
    data?: TData,
    options?: HttpClientRequestConfig,
  ): Promise<HttpClientResponse<T>> {
    return this.request<T>(url, 'DELETE', data, options)
  }

  addHeaders(headers: Record<string, string>) {
    this.defaultHeaders = { ...this.defaultHeaders, ...headers }
  }

  removeHeader(header: string) {
    delete this.defaultHeaders[header]
  }

  addResponseInterceptor<T>(interceptor: ResponseInterceptor<T>) {
    this.responseInterceptors.push(interceptor)
  }

  addErrorInterceptor<T>(interceptor: ErrorInterceptor<T>) {
    this.errorInterceptors.push(interceptor)
  }
}
