import { mutate } from 'swr'
import { USER_KEY } from './'

export const clearCache = () => {
  mutate(USER_KEY, (data) => ({ ...data, currentUser: null }), false)
}

export const setAccount = (newAccount) => {
  if (newAccount) {
    mutate(
      USER_KEY,
      (data) => ({ ...data, currentUser: { ...data?.currentUser, account: newAccount } }),
      false,
    )
  }
}

export const setConditionalDisplayAttributes = async (newConditionalDisplayAttributes) => {
  if (newConditionalDisplayAttributes) {
    await mutate(
      USER_KEY,
      (data) => ({
        ...data,
        currentUser: {
          ...data?.currentUser,
          account: {
            ...data?.currentUser?.account,
            conditionalDisplayAttributes: newConditionalDisplayAttributes,
          },
        },
      }),
      false,
    )
  }
}

export const setGeoLocation = (newGeoLocation) => {
  if (newGeoLocation) {
    mutate(USER_KEY, (data) => ({ ...data, geoLocation: newGeoLocation }), false)
  }
}

export const setMembershipActivity = (newMembershipActivity) => {
  if (newMembershipActivity) {
    mutate(
      USER_KEY,
      (data) => ({
        ...data,
        currentUser: { ...data?.currentUser, membershipActivity: newMembershipActivity },
      }),
      false,
    )
  }
}

export const setProfile = (newProfile) => {
  if (newProfile) {
    mutate(
      USER_KEY,
      (data) => ({ ...data, currentUser: { ...data?.currentUser, profile: newProfile } }),
      false,
    )
  }
}

export const setSubscription = (newSubscription) => {
  if (newSubscription) {
    mutate(
      USER_KEY,
      (data) => ({ ...data, currentUser: { ...data?.currentUser, subscription: newSubscription } }),
      false,
    )
  }
}

export const setUser = (newUser) => {
  if (newUser) {
    obe.analytics.identify(newUser.id, { email: newUser.email })
    mutate(USER_KEY, (data) => ({ ...data, currentUser: newUser }), false)
  }
}
