import { AnalyticsBrowser } from '@segment/analytics-next'
import * as Sentry from '@sentry/nextjs'
import { api } from 'src/api'
import { timeout } from 'src/utils/timeout'
import { retrieveUtmParameters } from 'src/utils/retrieveUtmParameters'

const ajs =
  process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
    ? new AnalyticsBrowser()
    : AnalyticsBrowser.load(
        { writeKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY ?? '' },
        {
          obfuscate: true,
          integrations: {
            All: false,
            'Google Analytics': true,
            'Segment.io': true,
            'Google Tag Manager': true,
            Mixpanel: true,
          },
        },
      )

type Traits = Record<string, unknown>

class ObeAnalytics {
  _properties: () => Record<string, unknown>

  constructor() {
    this._properties = () => ({
      isLoggedIn: api.isLoggedIn,
    })
  }

  async ready() {
    if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
      return true
    }

    return timeout(ajs.ready(), 3000)
  }

  anonymousId(captureException = true) {
    try {
      if (typeof window !== 'undefined') {
        return ajs.instance?.user().anonymousId()
      }

      return undefined
    } catch (error) {
      if (captureException) Sentry.captureException(error, { level: 'warning' })

      return undefined
    }
  }

  identifyAnonymous(traits: Traits) {
    ajs.identify(traits)
  }

  identify(userId: string, traits?: Traits) {
    try {
      if (userId && typeof window !== 'undefined') {
        if (userId === 'anonymous') {
          throw new Error(`Invalid userId in identify: ${userId}`)
        }

        ajs.identify(userId, traits)
        Sentry.setUser({ id: userId })
      }
    } catch (error) {
      Sentry.captureException(error, { level: 'warning' })
    }
  }

  alias(newId: string, previousId: string | undefined, callback: () => void) {
    if (newId && typeof window !== 'undefined') {
      ajs.alias(newId, previousId, callback)
    }
  }

  page(name: string, properties = {}) {
    if (typeof window !== 'undefined') {
      ajs.page(name, {
        ...this._properties(),
        ...properties,
        utmAd: retrieveUtmParameters()?.utm_ad,
        utmPromo: retrieveUtmParameters()?.utm_promo,
      })
    }
  }

  track(event = '', properties = {}) {
    if (typeof window !== 'undefined' && event) {
      ajs.track(event, {
        ...this._properties(),
        ...properties,
      })
    }
  }

  resetUserId() {
    if (typeof window !== 'undefined') {
      Sentry.setUser(null)
      ajs.reset()
    }
  }
}

const _analytics = new ObeAnalytics()

export const analytics = _analytics
