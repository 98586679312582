const memoryStorageFactory = (): Storage => {
  let _data = {} as Record<string, string>
  return {
    getItem: (key: string) =>
      Object.prototype.hasOwnProperty.call(_data, key) ? _data[key] : null,
    removeItem: (key) => delete _data[key],
    setItem: (key, value) => (_data[key] = String(value)),
    clear: () => (_data = {}),
    key: (index) => Object.keys(_data)[index],
    length: Object.keys(_data).length,
  }
}

const hasStorageType = (type: 'session' | 'local') => {
  const key = '__phnglui_mglwnafh__'
  try {
    window[`${type}Storage`].setItem(key, key)
    window[`${type}Storage`].removeItem(key)
    return true
  } catch (exception) {
    return false
  }
}

const session = hasStorageType('session') ? window.sessionStorage : memoryStorageFactory()

const local = hasStorageType('local') ? window.localStorage : memoryStorageFactory()

export const storage = {
  local,
  session,
}
