import { getCurrentUser } from 'src/models/user'
import { useRouter } from 'src/hooks/useRouter'
import useSwr, { mutate, useSWRConfig } from 'swr'
import { UserType, GeolocationType } from 'src/types/User'

export {
  clearCache,
  setAccount,
  setConditionalDisplayAttributes,
  setGeoLocation,
  setMembershipActivity,
  setProfile,
  setSubscription,
  setUser,
} from './setters'

export const USER_KEY = '/api/v3/users/me'
export const loadUser = () => mutate(USER_KEY)

interface UserState {
  canAccessLevelUpClub: boolean
  canUseSocialFeatures: boolean
  canSelfCancel: boolean
  isCanceled: boolean
  startDate?: string | null
  isGuest: boolean
  isInactive: boolean
  isMember: boolean
  isNonbillable: boolean
  isPremiumStatus: boolean
  isStripeUser: boolean
  hasFreemiumEnabled: boolean
  hasFreemiumClasses: boolean
  hasFinishedGexQuiz?: boolean
  showRecStars: boolean
  username?: string | null
  isPartnershipFreemium: boolean
  freemiumExpired: boolean
  geoLocation: GeolocationType | null
  subscriptionEndDate?: string | null
  isAnnual?: boolean
}

interface UserDataState {
  currentUser: UserType | null
  geoLocation: GeolocationType | null
}

export const useUser = () => {
  const { cache } = useSWRConfig()
  const router = useRouter()

  const { data, error, isLoading, isValidating, mutate } = useSwr<UserDataState, Error>(
    USER_KEY,
    getCurrentUser,
    {
      revalidateOnMount: !cache.get(USER_KEY) || router.asPath.includes('plans'),
      revalidateOnFocus: router.asPath.includes('videos'),
      revalidateOnReconnect: false,
    },
  )

  if (error) {
    return {
      isLoading: isValidating,
      isLoadingInitialState: isLoading,
      error,
      currentUser: null,
      refresh: () => null,
      ...getUserState(null, null),
    }
  }

  if (!data) {
    return {
      isLoading: isValidating,
      isLoadingInitialState: isLoading,
      currentUser: null,
      refresh: () => null,
      ...getUserState(null, null),
    }
  }

  return {
    isLoading: false,
    isLoadingInitialState: false,
    currentUser: data.currentUser,
    refresh: mutate,
    ...getUserState(data.currentUser, data.geoLocation),
  }
}

const getUserState = (
  currentUser: UserType | null,
  geoLocation: GeolocationType | null,
): UserState => {
  const isFreemiumPartnershipExpired =
    currentUser?.subscription?.plan === 'freemium' &&
    new Date().getTime() >= new Date(currentUser?.subscription?.endDate).getTime()
  return {
    canAccessLevelUpClub: !!currentUser?.account?.perks?.challenges,
    canSelfCancel:
      currentUser?.subscription?.canSelfCancel ||
      (geoLocation?.countryCode === 'US' &&
        ['California', 'New York', 'Vermont'].includes(geoLocation?.region)),
    canUseSocialFeatures: !!currentUser?.account?.active && !!currentUser?.profile?.username,
    geoLocation,
    isCanceled:
      !currentUser?.account?.nonBillable && currentUser?.subscription?.status === 'canceled',
    startDate: currentUser?.subscription?.originalStartDate,
    isGuest: currentUser === null,
    isInactive:
      !currentUser?.account?.nonBillable && currentUser?.subscription?.status === 'inactive',
    isMember:
      !!currentUser?.account?.active ||
      !!currentUser?.freemiumEligibility ||
      currentUser?.subscription?.plan === 'freemium',
    isNonbillable: !!currentUser?.account?.nonBillable,
    isPremiumStatus: ((plan) => plan === 'standard_yearly' || plan === 'standard_quarterly')(
      currentUser?.subscription?.plan,
    ),
    isStripeUser: currentUser?.subscription?.paymentGateway === 'stripe',
    subscriptionEndDate: currentUser?.subscription?.endDate,
    hasFinishedGexQuiz:
      !!currentUser?.account?.active &&
      currentUser?.account?.conditionalDisplayAttributes?.hasFinishedGexOnboardingQuiz,
    showRecStars: currentUser?.account?.conditionalDisplayAttributes?.showRecStars !== false,
    username: currentUser?.profile?.username,
    isPartnershipFreemium: currentUser?.subscription?.plan === 'freemium',
    hasFreemiumEnabled:
      (!currentUser?.account?.active && !!currentUser?.freemiumEligibility) ||
      currentUser?.subscription?.plan === 'freemium',
    hasFreemiumClasses:
      (currentUser?.freemiumClassesRemaining || 0) > 0 && !isFreemiumPartnershipExpired,
    freemiumExpired: isFreemiumPartnershipExpired,
    isAnnual: !!currentUser?.account?.annual,
  }
}

export default useUser
