'use client'

import { useCallback, useMemo } from 'react'
import {
  useRouter as useNextRouter,
  usePathname,
  useSearchParams,
  useParams,
} from 'next/navigation'

interface PushOptions {
  scroll: boolean
}

export const useRouter = () => {
  const router = useNextRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const params = useParams()

  const _push = router.push

  const push = useCallback(
    (href: string, options?: PushOptions) => {
      window?.clearAllTimeouts?.()
      _push(href, options)
    },
    [_push],
  )

  // for backward compatibility
  const query = useMemo(
    () => ({
      ...Array.from(searchParams.entries()).reduce(
        (prev, [key, val]) => ({
          ...prev,
          [key]: val,
        }),
        {},
      ),
      ...params,
    }),
    [searchParams, params],
  )

  return {
    ...router,
    pathname,
    asPath: pathname ?? '',
    query,
    push,
  }
}
